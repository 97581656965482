'use strict';

/**
 * 高速バス Information API より情報を取得し、データを表示するプラグインです。
 *
 * 日本語PC版
 *
 * 必須プラグイン moment.js
 *
 * Created by mitsuru on 2017/01/10.
 */
(function ($) {
  let _elem = null;
  let _options = {
    url: 'https://info.kosokubus.com',
    language: 'ja',
    endPoint: 'api-info',
    callback: 'createInformation',
    career: 'pc'
  };

  /**
   * お知らせリスト生成 多言語版
   *
   * @param {Object} data
   */
  let createInformationOther = function (data) {
    if (data === null || data.length <= 0) {
      // お知らせが無い時は何もしない
    } else {
      _elem.parent().show();
      $.each(data, (i, val) => {
        let item = '<li><a href="' + val.link_url + '" target="_blank">'
          + '<span class="date">' + moment(val.post_date).format('YYYY/MM/DD') + '</span>'
          + '<span class="txt">' + val.post_title + '</span>'
          + '</a></li>';
        _elem.append(item);
      });
    }
  };

  /**
   * お知らせリスト生成 日本語版
   *
   * @param {Object} data
   */
  let createInformationSp = function (data) {
    if (data === null || data.length <= 0) {
      // お知らせが無い時は非表示
      console.log('news was not. hide parent.');
      _elem.parent().hide();
    } else {
      _elem.parent().show();
      $.each(data, (i, val) => {
        let item = '<p class="item">'
          + '<span class="date">' + moment(val.post_date).format('YYYY/MM/DD') + '</span>'
          + '<a href="' + val.link_url + '" ' +
          'target="_blank">'
          + val.post_title + '</a>'
          + '</p>';
        _elem.append(item);
      });
    }
  };

  /**
   * お知らせリスト生成 日本語版
   *
   * @param {Object} data
   */
  let createInformation = function (data) {
    if (data === null || data.length <= 0) {
      let item = '<dd>現在お知らせはありません。</dd>';
      _elem.append(item);
    } else {
      $.each(data, (i, val) => {
        let item = '<dd><p class="item">'
          + '<span class="date">' + moment(val.post_date).format('YYYY/MM/DD') + '</span>'
          + '<a href="' + val.link_url + '" ' +
          'target="_blank" ' +
          ' onclick="window.open(this.href,\'\',\'resizable=yes,location=no,menubar=no,scrollbars=yes,' +
          'status=no,toolbar=no,fullscreen=no,dependent=no,width=930,height=720,status\'); return false;">'
          + val.post_title + '</a>'
          + '</p></dd>';
        _elem.append(item);
      });
    }
  };

  /**
   * getInformation
   *
   * 対象のAPIを呼び出して、お知らせリストを生成します。
   * 引数のsuccessには、ajaxのsuccessで返却される引数'data'を引数に持つ無名関数を用意しておいて下さい。
   *
   * @param {Object} elem
   * @param {Object} success
   */
  function getInformation(elem, success) {
    _elem = elem;

    $.ajax({
      type: 'GET',
      url: _options.url + '/' + _options.language + '/' + _options.endPoint,
      dataType: 'jsonp',
      success: data => {
        success(data);
      },
      error: (XMLHttpRequest, textStatus, errorThrown) => {
        success(null);
        // console.log('api error!');
        // console.log(XMLHttpRequest);
        // console.log(textStatus);
        // console.log(errorThrown);
      }

    });
  }

  let methods;
  methods = {
    init: options => {
      if (options === undefined) {
        return;
      }
      if (options.url !== undefined) {
        _options.url = options.url;
      }
      if (options.endPoint !== undefined) {
        _options.endPoint = options.endPoint;
      }
      if (options.career !== undefined) {
        _options.career = options.career;
      }

      // 言語チェック
      let lang = $('html').attr('lang');
      if (lang === 'cn') {
        _options.language = 'zh-CN';
      } else if (lang === 'tw') {
        _options.language = 'zh-TW';
      } else if (lang === 'en') {
        _options.language = 'en';
      } else if (lang === 'ko') {
        _options.language = 'ko';
      } else {
        _options.language = 'ja';
      }

    },
    destroy: () => {
      // ...
    },
    reposition: () => {
      // ...
    },
    show: function () {
      if (_options.career === 'sp') {
        getInformation(this, createInformationSp);
      } else if (_options.career === 'other') {
        getInformation(this, createInformationOther);
      } else {
        getInformation(this, createInformation);
      }
    }
    // hide: () => {
    //   // ...
    // },
    // update: content => {
    //   // ...
    // }
  };

  $.fn.busInfoPlugin = function (method) {
    if (methods[method]) {
      return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
    } else if (typeof method === 'object' || !method) {
      return methods.init.apply(this, arguments);
    }
    $.error('Method ' + method + ' does not exist on jQuery.tooltip');
  };
})(jQuery);
